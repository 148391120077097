<template>
    <v-app id="App"  class="">
        <div class="default-container dash">
            <!-- <v-container fluid class=""> -->
            <router-view ></router-view>
        <!-- </v-container> -->
        </div>
        
    </v-app>
  </template>
  
  <script>
  export default {
    data() {
      return {
        drawer: false,
        profileDialog: false,
        menu: false,
        profileModal: {},
      };
    },
    methods: {
      
    },
  };
  </script>
  <style>
  #App {
    font-size: 0.9rem;
    /* height: 100vh; */
    /* width: 100vw; */
  }

  .default-container {
        height: 100vh;
    width: 100vw;
  }
  
  
  
  </style>